import { Routes } from '@angular/router';

import { AdminGuard } from './guards/admin.guard';
import { canDeactivateGuard } from './guards/can-deactivate.guard';
import { userLoggedInAndRegisteredGuard as UserLoggedInAndRegisteredGuard } from './guards/user-logged-in-and-registered.guard';
import { userLoggedInGuard as UserLoggedInGuard } from './guards/user-logged-in.guard';
import { NotFoundComponent } from './not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then((c) => c.DashboardComponent),
    canActivate: [UserLoggedInAndRegisteredGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'register',
    title: 'Buzwave - Register',
    data: { showInMenu: false, showAtTop: true },
    loadComponent: () => import('./register/register.component').then((c) => c.RegisterComponent),
    canActivate: [UserLoggedInGuard],
  },
  {
    path: 'register-pixel',
    title: 'Buzwave - Install pixel',
    data: { showInMenu: false, showAtTop: true },
    loadComponent: () =>
      import('./register-pixel/register-pixel.component').then((c) => c.RegisterPixelComponent),
    canActivate: [UserLoggedInGuard],
  },
  {
    path: 'home',
    title: 'Home',
    data: { showInMenu: true, showAtTop: true, fontIcon: 'home' },
    loadComponent: () =>
      import('./dashboard/dashboard.component').then((c) => c.DashboardComponent),
    canActivate: [UserLoggedInAndRegisteredGuard],
  },
  {
    path: 'campaigns',
    title: 'Campaigns',
    data: { showInMenu: true, showAtTop: true, fontIcon: 'campaign', iconClass: 'campaignIcon' },
    loadComponent: () =>
      import('./campaign/campaign-page.component').then((c) => c.CampaignPageComponent),
    canActivate: [UserLoggedInAndRegisteredGuard],
  },
  {
    path: 'campaigns/create',
    title: 'Create Campaign',
    loadComponent: () =>
      import('./create-campaign/create-campaign-page.component').then(
        (c) => c.CreateCampaignPageComponent,
      ),
    canActivate: [UserLoggedInAndRegisteredGuard],
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: 'campaigns/edit',
    title: 'Edit Campaign',
    loadComponent: () =>
      import('./create-campaign/create-campaign-page.component').then(
        (c) => c.CreateCampaignPageComponent,
      ),
    canActivate: [UserLoggedInAndRegisteredGuard],
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: 'influencers',
    title: 'Influencers',
    data: { showInMenu: true, showAtTop: true, fontIcon: 'groups' },
    loadComponent: () =>
      import('./influencers/influencers.component').then((c) => c.InfluencersComponent),
    canActivate: [UserLoggedInAndRegisteredGuard],
  },
  {
    path: 'install',
    title: 'Install',
    data: { showInMenu: true, showAtTop: true, fontIcon: 'power' },
    loadComponent: () => import('./install/install.component').then((c) => c.InstallComponent),
    canActivate: [UserLoggedInAndRegisteredGuard],
  },
  {
    path: 'account',
    title: 'Account settings',
    data: { showInMenu: true, showAtBottom: true, fontIcon: 'assured_workload', adminOnly: false },
    loadComponent: () => import('./account-settings/account-settings.component').then((c) => c.AccountSettingsComponent),
    canActivate: [UserLoggedInAndRegisteredGuard],
  },
  {
    path: 'settings',
    title: 'User settings',
    data: { showInMenu: true, showAtBottom: true, fontIcon: 'settings', adminOnly: false },
    loadComponent: () => import('./settings/settings.component').then((c) => c.SettingsComponent),
    canActivate: [UserLoggedInAndRegisteredGuard],
  },
  {
    path: 'admin',
    title: 'Admin',
    data: {
      showInMenu: true,
      showAtBottom: true,
      fontIcon: 'admin_panel_settings',
      adminOnly: true,
    },
    loadComponent: () => import('./admin/admin.component').then((c) => c.AdminComponent),
    canActivate: [AdminGuard],
  },
  {
    path: 'loading',
    title: 'Loading',
    data: { showInMenu: false },
    loadComponent: () => import('./loading/loading.component').then((c) => c.LoadingComponent),
  },
  {
    path: 'login',
    title: 'Loading',
    data: { showInMenu: false },
    loadComponent: () => import('./loading/loading.component').then((c) => c.LoadingComponent),
  },
  {
    path: 'signout',
    data: { showInMenu: false },
    loadComponent: () => import('./sign-out/sign-out.component').then((c) => c.SignOutComponent),
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full',
  },
];
