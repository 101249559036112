import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { UserService } from '../shared/services/user.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const userLoggedInGuard: CanActivateFn = (route, state) => {
  const userService: UserService = inject(UserService);
  return userService.userLoggedIn$;
};
